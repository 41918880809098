<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.4325 6.43252L11.7153 6.43249C11.9012 6.43249 12.052 6.28172 12.052 6.09574C12.052 5.90976 11.9012 5.75897 11.7153 5.75899L6.43248 5.759L6.43249 0.476249C6.43249 0.290257 6.28172 0.139483 6.09574 0.139496C5.90976 0.139508 5.75897 0.290271 5.75898 0.476251L5.75899 5.75899L0.476234 5.75902C0.290243 5.75902 0.139469 5.9098 0.139481 6.09578C0.139481 6.18876 0.177186 6.27295 0.238132 6.33389C0.299077 6.39484 0.383251 6.43253 0.476247 6.43254L5.75898 6.43251L5.75898 11.7153C5.75898 11.8082 5.79669 11.8924 5.85763 11.9534C5.91858 12.0143 6.00275 12.052 6.09575 12.052C6.28174 12.052 6.43251 11.9012 6.4325 11.7153L6.4325 6.43252V6.43252Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 13 13" },
    width: { type: String, default: "13" },
    height: { type: String, default: "13" },
    color: { type: String, default: "#000" },
  },
};
</script>