<template>
  <div
    class="autocomplete-input"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
    :id="id"
    v-scroll-to="{
      el: `${scrollId}`,
      container: '#scroll-block',
      offset: -50,
      onStart: onStart,
      onDone: onDone,
    }"
  >
    <div v-if="!image" class="autocomplete-input__selected">
      <Tag
        v-for="(i, index) in selectedItems"
        :key="`${id}-selected-${index}`"
        class="tag--capitalize"
        :class="{ 'tag--selected': i.selected }"
        :value="i.title"
        :close="true"
        @deleteTag="selectFilter(i)"
      ></Tag>
    </div>
    <div
      class="autocomplete-input__input"
      :class="{ 'autocomplete-input__input--loading': loading }"
    >
      <input
        required
        type="text"
        v-model="item"
        @input="searchMatches"
        @keyup.enter.prevent="createItem"
        @focus="focusOpen ? searchMatches() : false"
        @focusin="focus = true"
        @focusout="focus = false"
        :disabled="disabled"
      />
      <label class="autocomplete-input__label">
        {{ placeholder }}
        <span class="autocomplete-input__label_required" v-if="required">*</span>
      </label>
      <IconLoader
        class="autocomplete-input__icon autocomplete-input__icon--loading"
        :class="{ 'autocomplete-input__icon--single': !creating }"
        v-if="loading"
      ></IconLoader>
      <span
        v-if="creating"
        class="autocomplete-input__icon"
        :class="{ 'autocomplete-input__icon--disabled': !item }"
        @click="
          $emit('addItem', item);
          item = '';
        "
      >
        Add {{ placeholder }}
        <IconButton class="button--xsm">
          <IconPlus width="16" height="16"></IconPlus>
        </IconButton>
      </span>
    </div>
    <div
      class="autocomplete-input__match"
      :class="{ 'autocomplete-input__match--image': image }"
      v-if="open"
    >
      <div class="autocomplete-input__match_body">
        <p class="autocomplete-input__match_title">Search {{ placeholder }}</p>
        <template v-if="matches.length">
          <ul>
            <li v-for="(match, index) in matches" :key="`${id}-match-${index}`">
              <button @click.prevent="selectFilter(match)" class="autocomplete-input__match_item">
                {{ match.title }}
              </button>
            </li>
          </ul>
        </template>
        <h6 v-else>No Results Found</h6>
      </div>
    </div>
    <draggable
      v-if="image"
      class="autocomplete-input__selected-body"
      v-model="selectedItems"
      group="artworks"
      @start="drag = true"
      @end="drag = false"
      @change="changeOrder"
    >
      <div
        v-for="element in selectedItems"
        :key="element.id"
        class="autocomplete-input__selected-item"
      >
        <img :src="element.search_image" :alt="element.title" />
        <p>
          {{ element.title }}
          <span
            v-if="hasStatus"
            class="autocomplete-input__selected-item_status"
            :class="{ 'autocomplete-input__selected-item_status--active': element.status }"
          ></span>
        </p>
        <div
          class="autocomplete-input__selected-item_container autocomplete-input__selected-item_container--reorder"
        >
          <IconMoveDots class="autocomplete-input__selected-item_svg"></IconMoveDots>
        </div>
        <div
          class="autocomplete-input__selected-item_container autocomplete-input__selected-item_container--close"
          @click="selectFilter(element)"
        >
          <IconCloseBold class="autocomplete-input__selected-item_svg"></IconCloseBold>
        </div>
      </div>
    </draggable>
    <p class="autocomplete-input__error">{{ validateError }}</p>
  </div>
</template>
<script>
import draggable from "vuedraggable";

import IconLoader from "@/components/icons/IconLoader.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import Tag from "@/components/Tag.vue";
import IconCloseBold from "@/components/icons/IconCloseBold";
import IconMoveDots from "@/components/icons/IconMoveDots";

import { scroller } from "vue-scrollto/src/scrollTo";
export default {
  props: {
    id: { type: String, default: "autocomplete" },
    creating: { type: Boolean, default: true },
    focusOpen: { type: Boolean, default: false },
    image: { type: Boolean, default: false },
    hasStatus: { type: Boolean, default: false },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: { type: String },
    loading: { type: Boolean, default: false },
    matches: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
  },
  components: { draggable, IconLoader, IconButton, IconPlus, Tag, IconCloseBold, IconMoveDots },
  data() {
    return {
      item: "",
      selectedItems: [],
      open: false,
      focus: false,
      cursorEnter: false,
      scrollId: "",
      canClose: true,
      orderedItems: [],
    };
  },
  created() {
    this.selectedItems = this.items;
    // this.orderedItems = JSON.parse(JSON.stringify(this.items));
    this.sortable(this.items);
    this.scrollId = `#${this.id}`;
  },
  watch: {
    items(val) {
      this.selectedItems = val;
    },
    open(val) {
      if (!val) {
        this.item = "";
        this.$emit("searchMatches", { search: "", excludeIdx: this.selectedItems });
      }
    },
    focus(val) {
      if (!val && !this.cursorEnter) {
        this.open = false;
      }
    },
  },
  methods: {
    selectFilter(item) {
      let selectedItems = this.selectedItems;
      let newEl = true;
      if (selectedItems.length) {
        for (let el of selectedItems) {
          if (+el.id === +item.id) {
            newEl = false;
            break;
          }
        }

        if (newEl) {
          selectedItems.push(item);
        } else {
          selectedItems = selectedItems.filter(i => {
            return i.id != item.id;
          });
        }
      } else {
        selectedItems.push(item);
      }
      this.selectedItems = selectedItems;
      if (this.image) this.changeOrder();
      this.$emit("input");
      this.$emit("selectItem", this.selectedItems);
      this.$emit("searchMatches", { search: this.item, excludeIdx: this.selectedItems });
    },
    searchMatches() {
      this.$emit("searchMatches", { search: this.item, excludeIdx: this.selectedItems });
      this.open = true;
    },
    sortable(items) {
      // let type = this.artistName;
      let orderedItems = JSON.parse(JSON.stringify(items));
      orderedItems = orderedItems
        // .filter(el => {
        //   return el.artist_name === type;
        // })
        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
      this.selectedItems = orderedItems;
    },
    changeOrder() {
      let orderedItems = [];
      orderedItems = this.selectedItems.map((el, id) => {
        return (el = { ...el, order: id });
      });
      this.selectedItems = orderedItems;
      this.$emit("reorder", this.selectedItems);
    },
    createItem() {
      if (this.creating) {
        this.$emit("addItem", this.item);
        this.item = "";
      }
    },
    mouseenter() {
      this.cursorEnter = true;
    },
    mouseleave() {
      if (this.canClose && !this.focus) {
        this.open = false;
      }
      this.cursorEnter = false;
    },
    onStart() {
      this.canClose = false;
    },
    onDone() {
      this.canClose = true;
      this.open = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.autocomplete-input {
  position: relative;
  width: 100%;
  &__input {
    position: relative;
    margin-top: 10px;
    input {
      height: 9rem;
      padding: 0 15.5rem 0 2.7rem;
      border: 0.5px solid $lightGrey;
      border-radius: 12px;
      @include type();
      color: $lightBlack;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        height: 7rem;
        font-size: 14px;
      }
    }
    &--loading {
      input {
        padding-right: 20rem;
      }
    }
  }
  &--invalid {
    .autocomplete-input__input input {
      border-color: $error;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    @include type($fw: 500);
    cursor: pointer;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }

    &--loading {
      right: 16.5rem;
      cursor: default;
    }
    &--single {
      right: 15px;
    }
    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
    .button {
      margin-left: 10px;
    }
  }
  &__label {
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_required {
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
      color: $error;
      margin-left: 4px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  input:focus ~ &__label,
  input:valid ~ &__label {
    top: 0;
  }
  &__selected {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    &:empty {
      margin-top: 0;
    }
    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
      margin-top: 15px;
    }
    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 22.8rem;
      min-height: 22rem;
      border: 0.5px solid #bcbcbc;
      border-radius: 12px;
      padding: 12px 12px;
      background: $white;
      cursor: pointer;
      img {
        height: 100%;
        width: 100%;
        border-radius: 7px;
      }
      p {
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-align: left;
        margin-top: 10px;
        @include type($fs: 14px, $fw: 600);
        color: $black;
      }
      &_status {
        display: inline-block;
        margin-left: 15px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        border-radius: 50%;
        background-color: rgba($color: $greyDark, $alpha: 0.8);
        &--active{
          background-color: $green;
        }
      }
      &_container {
        position: absolute;
        top: 22px;
        right: 22px;
        padding: 0;
        border-radius: 5px;
        height: 26px;
        width: 26px;
        background-color: $white;
        &--close {
        }
        &--reorder {
          right: 56px;
          padding: 5px;
        }
      }
      &_svg {
        /* height: 12px;
        width: 12px; */
      }
    }
  }
  &__match {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    z-index: 9;
    padding-top: 11px;
    &--image {
      top: 10rem;
      transform: translateY(0);
      bottom: auto;
    }
    &_body {
      background: #fff;
      border-radius: 12px;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24);
      padding: 24px 22px 16px;
      max-height: 300px;
      overflow-y: auto;
      border: 1px solid #efefef;
    }
    &_title {
      @include type($fw: 700);
      text-transform: capitalize;
      margin-bottom: 25px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    &_item {
      @include type();
      color: $white;
      text-align: center;
      border-radius: 8px;
      background-color: $greyDark;
      padding: 6px 2rem;
      margin-bottom: 8px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $blueLight;
        color: $blue;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }

    h6 {
      @include type($fw: 500);
      color: rgba($color: #111111, $alpha: 0.7);
      margin-bottom: 15px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
  }
}
</style>

