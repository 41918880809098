<template>
  <div class="uploader">
    <div
      class="uploader__image"
      :class="{ 'uploader__image--selected': image }"
      :style="{ backgroundImage: image ? `url(${image})` : '' }"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <div class="uploader__input">
        <input
          class="uploader__input_input"
          type="file"
          :name="`add${id}`"
          :id="`add-${id}`"
          @change="onAddChange({ element: $event })"
          ref="fileAdd"
          :accept="typesString"
          multiple
          :disabled="disabled"
        />
        <input
          class="uploader__input_input"
          type="file"
          :name="`edit${id}`"
          :id="`edit-${id}`"
          @change="onEditChange({ element: $event, id: id })"
          ref="fileEdit"
          :accept="typesString"
          :disabled="disabled"
        />
        <div class="uploader__input_label">
          <span class="loader" v-if="loading"></span>
          <template v-else-if="!image">
            <p class="uploader__input_label_text">Upload</p>
            <label class="uploader__input_label_button" :for="`add-${id}`">
              <IconPlus width="20" height="20" />
            </label>
            <p class="uploader__input_label_text">
              <span class="uploader__input_label_text--uppercase">{{ acceptString }}</span>
              <span>up to {{ size }} MB</span>
            </p>
          </template>
          <div
            v-else-if="reorder"
            class="uploader__input_label_actions uploader__input_label_actions_reorder"
          >
            <IconButton class="uploader__input_label_button">
              <IconMoveDots />
            </IconButton>
            <IconButton class="uploader__input_label_button" @click="$emit('removeImage', id)">
              <IconCloseBold />
            </IconButton>
          </div>
          <div v-else class="uploader__input_label_actions">
            <label :for="`edit-${id}`" class="uploader__input_label_button">
              <IconEdit />
            </label>
            <IconButton class="uploader__input_label_button" @click="$emit('removeImage', id)">
              <IconDelete />
            </IconButton>
          </div>
        </div>

        <template v-if="errors != null">
          <p class="uploader__input_error" v-for="(error, id) in errors" :key="id">
            <span v-for="(i, id) in error" :key="id">{{ i }}</span>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconDelete from "@/components/icons/IconDelete.vue";
import IconCloseBold from "@/components/icons/IconCloseBold";
import IconMoveDots from "@/components/icons/IconMoveDots";

export default {
  props: {
    id: { type: [String, Number], default: "upload" },
    accept: {
      type: Array,
      default() {
        return ["png", "jpeg", "jpg", "webp"];
      },
    },
    size: { type: [Number, String], default: "15" },
    image: { type: String, require: true },
    disabled: { type: Boolean, default: false },
    loadingImage: { type: Boolean, default: false },
    reorder: { type: Boolean, default: false },
  },
  components: {
    IconButton,
    IconEdit,
    IconPlus,
    IconDelete,
    IconCloseBold,
    IconMoveDots,
  },
  data() {
    return {
      types: "",
      acceptString: "",
      typesString: "",
      errors: null,
      loading: false,
    };
  },
  created() {
    this.types = this.accept.map(el => {
      return `image/${el}`;
    });
    this.typesString = this.types.join(", ");
    this.acceptString = this.accept.join(", ");
  },
  watch: {
    loadingImage(val) {
      if (!val) this.loading = false;
    },
  },
  methods: {
    checkSize(val) {
      const size = parseFloat(val.size / (1024 * 1024)).toFixed(2);
      let correct = false;
      if (+size > +this.size) {
        this.$toasted.show(`Image ${val.name} is too large, must be below ${this.size}MB`, {
          duration: 3000,
          type: "error",
        });
      } else {
        correct = true;
      }
      return correct;
    },

    checkType(val) {
      const type = this.types.includes(val.type);
      if (!type) {
        this.$toasted.show(`Incorrect format ${val.name}, must be JPG, PNG or WEBP`, {
          duration: 3000,
          type: "error",
        });
      }
      return type;
    },

    async onAddChange({ element, dropEl }) {
      for (let el of element.target.files) {
        this.loading = true;
        const size = this.checkSize(el);
        const type = this.checkType(el);
        if (size && type) {
          this.$emit("upload", el);
        } else this.loading = false;
      }
      dropEl ? dropEl.currentTarget.classList.remove("uploader__image--active") : false;
    },
    async onEditChange({ element, dropEl, id }) {
      const size = await this.checkSize(element.target.files[0]);
      const type = await this.checkType(element.target.files[0]);
      if (size && type) {
        this.$emit("editImage", { el: element.target.files[0], idx: id });
      } else this.loading = false;
      dropEl ? dropEl.currentTarget.classList.remove("uploader__image--active") : false;
    },

    drop(event) {
      event.preventDefault();
      //if (!this.image) {}
      const eventEl = { target: { files: event.dataTransfer.files } };
      this.onAddChange({ element: eventEl, dropEl: event });
    },
    dragover(event) {
      event.preventDefault();
      if (!this.image && !event.currentTarget.classList.contains("uploader__image--active")) {
        event.currentTarget.classList.add("uploader__image--active");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove("uploader__image--active");
    },
  },
};
</script>
<style lang="scss" scoped>
.uploader {
  width: 29rem;
  height: 19.8rem;
  border-radius: 8px;
  border: 1px solid $grey;
  overflow: hidden;
  &--lg {
    width: 100%;
    height: 29rem;
  }
  &--reorder {
    &.uploader {
      width: 25.8rem;
    }
    .uploader {
      &__input {
        &_label {
          position: relative;
          &_actions_reorder {
            position: absolute;
            top: 12px;
            right: 12px;
            .uploader__input_label_button {
              height: 26px;
              width: 26px;
              &.button {
                margin: 0 4px;
              }
            }
          }
        }
      }
    }
  }
  &__image {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center;
    &--selected {
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba($color: $black, $alpha: 0.45);
        z-index: 1;
      }
    }
    &--active {
      background-color: $light2Grey;
    }
  }
  &__input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 2;
    &_input {
      position: absolute;
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
      z-index: -1;
    }
    &_label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      &_text {
        margin: 13px 0;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include type($fw: 500);
        color: $lightBlack;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 14px;
        }
        span {
          @include type($fw: 500);
          color: $lightBlack;
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            font-size: 14px;
          }
          &.uploader__input_label_text--uppercase {
            font-weight: 500;
            text-transform: uppercase;
            color: $lightBlack;
          }
        }
      }
      &_actions {
        display: flex;
        align-items: center;
        justify-content: center;
        .button {
          margin: 0 8px;
        }
      }
      &_button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4.4rem;
        width: 4.4rem;
        border: 1px solid $lightGrey;
        border-radius: 7px;
        background-color: $white;
        transition: all 0.3s ease;
        cursor: pointer;
        &:hover {
          background-color: $light2Grey;
        }
        svg {
          width: auto;
          height: auto;
          &::v-deep g {
            fill: $black;
          }
        }
      }
    }
  }
  .loader {
    display: inline-block;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    height: 70px;
    width: 70px;
    max-width: 70px;
    max-height: 70px;
    min-height: 70px;
    min-width: 70px;
    background: transparent !important;
    border: 3px solid $lightBlack;
    border-bottom-color: transparent;
    -webkit-animation: rotate 0.75s 0s linear infinite;
    animation: rotate 0.75s 0s linear infinite;
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
</style>
